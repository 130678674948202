// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'name',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'client',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'poi_ids',
    label: '',
    Object: 'value',
    width: '240'
  }, {
    prop: 'coupon_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'discount',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'target_amount',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'validity',
    label: '',
    Object: 'value',
    width: '240'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'usable_status',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
